<template>
	<ion-footer class="supplier">
		<img :src="require(`@/assets/supplier_logo.svg`)" /><!-- :src="image" with image="/assets/supplier_logo.png"(senza '@') --><!-- class="supplier-image"-->
		<ion-label color="dark" v-html=text></ion-label>
	</ion-footer>
</template>

<style scoped>
	.supplier {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		background-color: white;
		border-radius: 15px;
	}
	.supplier img {
		width: min(44vw, 44px);
		height: min(36vh, 36px);
	}
	.supplier ion-label {
		text-align: center;
		color: var(--ion-color-medium);
		margin-left: .4em;
	}
</style>

<script>
import { IonFooter, IonLabel } from '@ionic/vue';
import { defineComponent } from "vue";

	export default defineComponent({
		props: {
			text: { type: String, default: "powered by <strong>Bancolini Symbol</strong>" },
		},
		components: {
			IonFooter,
			IonLabel,
		},
	});
</script>
