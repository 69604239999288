import axios from "axios";
import { apiURL } from "../plugins/variables.js"

let config = {
	baseURL: apiURL,
};

const $axios = axios.create(config);

export default $axios;
