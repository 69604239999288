<template>
	<ion-page>
		<spot-title :title="pageTitle"></spot-title>
		<ion-content :fullscreen="true">
			<ion-progress-bar type="indeterminate" v-if="!isActive"></ion-progress-bar>
			<div class="flex-row-center-container" v-if="isActive">
				<spot-select
					:ref="fields[field.printer].name"
					:label="fields[field.printer].text"
					:allignLabel="fields[field.printer].allignLabel"
					:required="fields[field.printer].required"
					:placeholder="getPlaceHolder(field.printer)"
					:enabled="!isPrinting"
					:validationState="fields[field.printer].validationState"
					:value="fields[field.printer].value"
					:options="tablesData.printers"
					:optionField="fields[field.printer].refField"
					selectedLabel="Selezionato"
					deselectLabel="rimuovi"
					selectLabel="seleziona"
					@selected="detectPrinterSelection"
					@unselected="detectPrinterUnselection">
				</spot-select>
				<spot-select
					:ref="fields[field.print_template].name"
					:label="fields[field.print_template].text"
					:allignLabel="fields[field.print_template].allignLabel"
					:required="fields[field.print_template].required"
					:placeholder="getPlaceHolder(field.print_template)"
					:enabled="!isPrinting"
					:validationState="fields[field.print_template].validationState"
					:value="fields[field.print_template].value"
					:options="tablesData.printTemplates"
					:optionField="fields[field.print_template].refField"
					selectedLabel="Selezionato"
					deselectLabel="rimuovi"
					selectLabel="seleziona"
					@selected="detectPrintTemplateSelection"
					@unselected="detectPrintTemplateUnselection">
				</spot-select>
				<spot-select-custom
					:ref="fields[field.logo].name"
					:label="fields[field.logo].text"
					:allignLabel="fields[field.logo].allignLabel"
					:required="fields[field.logo].required"
					:placeholder="getPlaceHolder(field.logo)"
					:enabled="!isPrinting"
					:validationState="fields[field.logo].validationState"
					:value="fields[field.logo].value"
					:options="tablesData.labelImages"
					:optionField="fields[field.logo].refField"
					:custom-label="customLabel"
					selectedLabel="Selezionato"
					deselectLabel="rimuovi"
					selectLabel="seleziona"
					@selected="detectLogoSelection"
					@unselected="detectLogoUnselection">
				</spot-select-custom><!--:custom-image="customImage"-->
			</div>
			<div class="flex-row-center-container" v-if="isActive">
				<spot-select
					:ref="fields[field.item].name"
					:label="fields[field.item].text"
					:allignLabel="fields[field.item].allignLabel"
					:required="fields[field.item].required"
					:placeholder="getPlaceHolder(field.item)"
					:enabled="!isPrinting"
					:validationState="fields[field.item].validationState"
					:value="fields[field.item].value"
					:options="tablesData.items"
					:optionField="fields[field.item].refField"
					selectedLabel="Selezionato"
					deselectLabel="rimuovi"
					selectLabel="seleziona"
					@selected="detectItemSelection"
					@unselected="detectItemUnselection">
				</spot-select>
				<spot-select-custom
					:ref="fields[field.description].name"
					:label="fields[field.description].text"
					:allignLabel="fields[field.description].allignLabel"
					:required="fields[field.description].required"
					:placeholder="getPlaceHolder(field.description)"
					:enabled="!isPrinting"
					:validationState="fields[field.description].validationState"
					:value="fields[field.description].value"
					:options="tablesData.items"
					:optionField="fields[field.description].refField"
					:custom-label="customDescription"
					selectedLabel="Selezionato"
					deselectLabel="rimuovi"
					selectLabel="seleziona"
					@selected="detectItemSelection"
					@unselected="detectItemUnselection">
				</spot-select-custom>
			</div>
			<ion-card v-if="isActive" disabled="true" color="medium">
				<ion-card-content v-if="fields[field.item].value" class="flex-row-left-container">
					<ion-item lines="none" class="line-of-3" v-for="field in itemContents" :key="field.id">
						<spot-input
							:ref="field.name"
							:label="field.text"
							:required="false"
							:placeholder="null"
							:value="field.value"
							:type="field.inputType">
						</spot-input>
					</ion-item>
				</ion-card-content>
			</ion-card>
			<div class="flex-row-center-container" v-if="isActive">
				<spot-input
					:ref="fields[field.sscc].name"
					:label="fields[field.sscc].text"
					:allignLabel="fields[field.sscc].allignLabel"
					:required="fields[field.sscc].required"
					:placeholder="getPlaceHolder(field.sscc)"
					:enabled="false"
					:validationState="fields[field.sscc].validationState"
					:value="fields[field.sscc].value"
					:type="fields[field.sscc].inputType"
					:clearInput="true">
				</spot-input>
				<spot-input
					:ref="fields[field.lot].name"
					:label="fields[field.lot].text"
					:allignLabel="fields[field.lot].allignLabel"
					:required="fields[field.lot].required"
					:placeholder="getPlaceHolder(field.lot)"
					:enabled="false"
					:validationState="fields[field.lot].validationState"
					:value="fields[field.lot].value"
					:type="fields[field.lot].inputType"
					:clearInput="true">
				</spot-input>
				<spot-date
					:ref="fields[field.production_date].name"
					:label="fields[field.production_date].text"
					:allignLabel="fields[field.production_date].allignLabel"
					:required="fields[field.production_date].required"
					:enabled="true"
					:validationState="fields[field.production_date].validationState"
					:value="fields[field.production_date].value"
					color="primary"
					:doneText="confirmText"
					:cancelText="undoText"
					@dateChanged="changedProductionDate">
				</spot-date>
			</div>
			<div class="flex-row-center-container" v-if="isActive">
				<spot-select
					:ref="fields[field.print_mode].name"
					:label="fields[field.print_mode].text"
					:allignLabel="fields[field.print_mode].allignLabel"
					:required="fields[field.print_mode].required"
					:placeholder="getPlaceHolder(field.print_mode)"
					:enabled="!isPrinting"
					:validationState="fields[field.print_mode].validationState"
					:value="fields[field.print_mode].value"
					:options="print_modes"
					:optionField="fields[field.print_mode].refField"
					selectedLabel="Selezionato"
					deselectLabel="rimuovi"
					selectLabel="seleziona"
					@selected="detectPrintModeSelection"
					@unselected="detectPrintModeUnselection">
				</spot-select>
				<spot-input
					:ref="fields[field.label_quantity].name"
					:label="fields[field.label_quantity].text"
					:allignLabel="fields[field.label_quantity].allignLabel"
					:required="fields[field.label_quantity].required"
					:placeholder="getPlaceHolder(field.label_quantity)"
					:enabled="!isPrinting"
					:validationState="fields[field.label_quantity].validationState"
					:value="fields[field.label_quantity].value"
					:type="fields[field.label_quantity].inputType"
					:clearInput="true"
					@textChanged="changedQuantity">
				</spot-input>
				<spot-button
					fill="outline"
					size="large"
					color="primary"
					icon="print"
					:enabled="checkFields()"
					:expand="null"
					:text="(isPrinting == true) ? labelEndPrint : labelPrint"
					@clicked="(isPrinting == true) ? cancelQueue(false) : openModal()">
				</spot-button>
				<ion-modal ref="modal" backdrop-dismiss="false" :is-open="isOpenModal" @didDismiss="modalDismissed">
					<ion-content>
						<ion-toolbar>
							<ion-title>{{	confirmPrintText }}</ion-title>
						</ion-toolbar>
						<div class="flex-column-left-container">
							<spot-date
								:ref="fields[field.production_date].name"
								:label="fields[field.production_date].text"
								:allignLabel="fields[field.production_date].allignLabel"
								:required="fields[field.production_date].required"
								:enabled="true"
								:validationState="fields[field.production_date].validationState"
								:value="fields[field.production_date].value"
								color="primary"
								:doneText="confirmText"
								:cancelText="undoText"
								@dateChanged="changedProductionDate">
							</spot-date>
							<spot-input
								:ref="fields[field.lot].name"
								:label="fields[field.lot].text"
								:allignLabel="fields[field.lot].allignLabel"
								:required="fields[field.lot].required"
								:placeholder="getPlaceHolder(field.lot)"
								:enabled="true"
								:validationState="(!fields[field.lot].value) ? false : true"
								:value="fields[field.lot].value"
								:type="fields[field.lot].inputType"
								:clearInput="true"
								@textChanged="changedLot">
							</spot-input>
							<spot-input
								v-if="fields[field.label_quantity].value == 1"
								:ref="itemContents[itemContent.weight].name"
								:label="itemContents[itemContent.weight].text"
								:allignLabel="false"
								:required="true"
								:placeholder="null"
								:enabled="(fields[field.label_quantity].value == 1 ? true : false)"
								:validationState="(!itemContents[itemContent.weight].value) ? false : true"
								:value="itemContents[itemContent.weight].value"
								:type="itemContents[itemContent.weight].inputType"
								@textChanged="changedItemWeight">
							</spot-input>
							<spot-input
								v-if="fields[field.label_quantity].value == 1"
								:ref="itemContents[itemContent.quantity].name"
								:label="itemContents[itemContent.quantity].text"
								:allignLabel="false"
								:required="true"
								:placeholder="null"
								:enabled="(fields[field.label_quantity].value == 1 ? true : false)"
								:validationState="(!itemContents[itemContent.quantity].value) ? false : true"
								:value="itemContents[itemContent.quantity].value"
								:type="itemContents[itemContent.quantity].inputType"
								@textChanged="changedItemQuantity">
							</spot-input>
							<spot-input
								v-if="fields[field.label_quantity].value == 1"
								:ref="fields[field.sscc].name"
								:label="fields[field.sscc].text"
								:allignLabel="fields[field.sscc].allignLabel"
								:required="fields[field.sscc].required"
								:placeholder="getPlaceHolder(field.sscc)"
								:enabled="(fields[field.label_quantity].value == 1 ? true : false)"
								:validationState="(!fields[field.sscc].value) ? false : true"
								:value="fields[field.sscc].value"
								:type="fields[field.sscc].inputType"
								:clearInput="true"
								@textChanged="changedSscc">
							</spot-input>
						</div>
						<div class="flex-row-center-container">
							<spot-button
								fill="outline"
								size="large"
								color="primary"
								:enabled="true"
								:expand="null"
								:text="undoText"
								@clicked="closeModal(false)">
							</spot-button>
							<spot-button
								fill="outline"
								size="large"
								color="primary"
								:enabled="(!fields[field.production_date].value || !fields[field.lot].value || !itemContents[itemContent.weight].value || !itemContents[itemContent.quantity].value || !fields[field.sscc].value) ? false : true"
								:expand="null"
								:text="confirmText"
								@clicked="closeModal(true)">
							</spot-button>
						</div>
					</ion-content>
				</ion-modal>
			</div>
			<!--
			<multiselect v-model="value" placeholder="Fav No Man’s Sky path" label="title" track-by="title" :options="options" :option-height="104" :custom-label="customLabel" :show-labels="false">
				<template slot="singleLabel" slot-scope="props"><img class="option__image" :src="options.img" alt="No Man’s Sky">
					<span class="option__desc">
						<span class="option__title">{{ option.title }}</span>
					</span>
				</template>
				<template slot="option" slot-scope="props">
					<img class="option__image" :src="options.img" alt="No Man’s Sky">
					<div class="option__desc">
						<span class="option__title">{{ options.title }}</span>
						<span class="option__small">{{ options.desc }}</span>
					</div>
				</template>
			</multiselect>
			-->
		</ion-content>
	</ion-page>
</template>

<style scoped>
	.flex-column-center-container {
		margin-top: 1em;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.flex-column-left-container {
		margin-top: 1em;
		text-align: left;
		display: flex;
		flex-direction: column;
		justify-content: left;
		align-items: left;
	}
	.flex-row-center-container {
		margin-top: 1em;
		text-align: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	.flex-row-left-container {
		margin-top: 1em;
		text-align: left;
		display: flex;
		flex-direction: row;
		/*justify-content: left;*/
		/*align-items: left;*/
		flex-wrap: wrap;
	}
	.line-of-3 {
		flex: 33%;
	}
	ion-modal {
		--height: 50%;
		--width: 50%;
		--border-radius: 16px;
		--box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	}
	ion-modal::part(backdrop) {
		background: var(--ion-color-primary-contrast);
		opacity: 1;
	}
	ion-modal ion-toolbar {
		--background: var(--ion-color-primary);
		--color: white;
	}
</style>

<script>
import { IonCard, IonCardContent, IonContent, IonItem, IonPage, IonProgressBar } from '@ionic/vue';
import { IonModal, IonToolbar, IonTitle } from '@ionic/vue';
import { print } from "ionicons/icons";
import { defineComponent } from 'vue';
//import Multiselect from 'vue-multiselect';
import SpotButton from "../components/SpotButton.vue";
import SpotInput from "../components/SpotInput.vue";
import SpotDate from "../components/SpotDate.vue";
import SpotSelect from "../components/SpotSelect.vue";
import SpotSelectCustom from "../components/SpotSelectCustom.vue";
import SpotSelectImage from "../components/SpotSelectImage.vue";
import SpotTitle from "../components/SpotTitle.vue";
import { checkUserSession, getUserInfo, settings, showMsgError, showMsgInfo, showMsgWarning, sleep } from "../plugins/common.js";
import { createPalletLabel, executePrinterAction, getAllTablesData, tablesData, tablesToBeRefreshed, updateBranch, updatePrinter } from "../plugins/customDatabase.js";
import { backendUrl } from "../plugins/variables.js";

	export default defineComponent({
		name: 'PalletPrint',
		props: {
			pageTitle: { type: String, default: "Stampa etichetta EAN" },
			unselected: { type: String, default: "Nessuno" },
			labelPrint: { type: String, default: "Stampa Etichette" },
			labelEndPrint: { type: String, default: "Termina Stampa Etichette" },
			messageTitleCancelQueue: { type: String, default: "Cancellazione coda di Stampa" },
			successCancelQueue: { type: String, default: "Coda di Stampa cancellata" },
			errorCancelQueue: { type: String, default: "Coda di Stampa non cancellata" },
			messageTitleSendLogo: { type: String, default: "Invio Immagine alla Stampante" },
			successSendLogo: { type: String, default: "Immagine caricata sulla Stampante" },
			errorSendLogo: { type: String, default: "Immagine non inviata alla Stampante" },
			successPrint: { type: String, default: "Coppia di Etichette stampate correttamente" },
			userCancelPrint: { type: String, default: "Stampa Etichette annullata dall'operatore" },
			errorPrint: { type: String, default: "Erore durante l'attività di stampa Etichette" },
			confirmPrintText: { type: String, default: "Conferma Stampa" },
			confirmText: { type: String, default: "Conferma" },
			undoText: { type: String, default: "Annulla" },
		},
		components: {
			IonPage,
			IonContent,
			SpotTitle,
			IonProgressBar,
			SpotSelect,
			SpotSelectCustom,
			SpotSelectImage,
			IonCard,
			IonCardContent,
			IonItem,
			SpotButton,
			SpotInput,
			SpotDate,
			IonModal,
			IonToolbar,
			IonTitle,
			//Multiselect,
		},
		data: () => ({
			isActive: null,
			isFirstLoad: true,
			isPrinting: null,
			undoPrint: false,
			isOpenModal: false,
			branchId: null,
			field: {
				printer: 0,
				print_template: 1,
				logo: 2,
				item: 3,
				description: 4,
				sscc: 5,
				lot: 6,
				production_date: 7,
				print_mode: 8,
				label_quantity: 9,
			},
			fields: [
				{ id: 0, name: 'printer', text: 'Stampante', allignLabel: false, placeholder: 'Seleziona Stampante', inputType: "combo", validationState: null, defaultValue: null, value: null, required: true, refField: 'name' },
				{ id: 1, name: 'print_template', text: 'Modello Etichetta', allignLabel: false, placeholder: 'Seleziona Modello Etichetta', inputType: "combo", validationState: null, defaultValue: null, value: null, required: true, refField: 'name' },
				{ id: 2, name: 'logo', text: 'Logo', allignLabel: false, placeholder: 'Seleziona Logo', inputType: "combo", validationState: null, defaultValue: null, value: null, required: true, refField: 'id' },
				{ id: 3, name: 'item', text: 'Codice Articolo', allignLabel: false, placeholder: 'Seleziona Codice Articolo', inputType: "combo", validationState: null, defaultValue: null, value: null, required: true, refField: 'code' },
				{ id: 4, name: 'description', text: 'Descrizione', allignLabel: false, placeholder: 'Seleziona Descrizione', inputType: "combo", validationState: null, defaultValue: null, value: null, required: true, refField: 'id' },
				{ id: 5, name: 'sscc', text: 'SSCC', allignLabel: false, placeholder: 'Inserisci SSCC', inputType: "text", validationState: null, defaultValue: null, value: null, required: true, refField: null },
				{ id: 6, name: 'lot', text: 'Lotto', allignLabel: false, placeholder: 'Inserisci Lotto', inputType: "text", validationState: null, defaultValue: null, value: null, required: true, refField: null },
				{ id: 7, name: 'production_date', text: 'Data Produzione', allignLabel: false, placeholder: 'Inserisci Data Produzione', inputType: "datetime", validationState: null, defaultValue: new Date().toISOString(), value: null, required: true, refField: null },
				{ id: 8, name: 'print_mode', text: 'Modalità', allignLabel: false, placeholder: 'Seleziona Modalità', inputType: "combo", validationState: null, defaultValue: null, value: null, required: true, refField: 'text' },
				{ id: 9, name: 'label_quantity', text: 'Numero Etichette', allignLabel: false, placeholder: 'Inserisci Nr. Etichette', inputType: "number", validationState: null, defaultValue: null, value: null, required: true, refField: null },
			],
			itemContent: {
				description: 0,
				details: 1,
				packages: 2,
				barcode: 3,
				weight: 4,
				quantity: 5,
				expiration: 6,
				month_end: 7,
				durability: 8,
			},
			itemContents: [
				{ id: 0, name: 'description', text: 'Descrizione', inputType: "text", value: null },
				{ id: 1, name: 'details', text: 'Dettagli', inputType: "text", value: null },
				{ id: 2, name: 'packages', text: 'Imballaggio', inputType: "text", value: null },
				{ id: 3, name: 'barcode', text: 'EAN 13 | ITF 14', inputType: "text", value: null },
				{ id: 4, name: 'weight', text: 'Peso', inputType: "number", value: null },
				{ id: 5, name: 'quantity', text: 'Quantità', inputType: "number", value: null },
				{ id: 6, name: 'expiration', text: 'Scadenza', inputType: "text", value: null },
				{ id: 7, name: 'month_end', text: 'Fine Mese', inputType: "text", value: null },
				{ id: 8, name: 'durability', text: 'Durabilità', inputType: "number", value: null },
			],
			print_mode: {
				manual: 0,
				automatic: 1,
			},
			print_modes: [
				{ id: 0, name: 'Off_line', text: 'Manuale', value: 2, rotation: 'N' },
				{ id: 1, name: 'On_line', text: 'Automatica', value: 1, rotation: 'I' },
			],
			/*
			value: { title: 'Explorer', desc: 'Discovering new species!', img: 'https://vue-multiselect.js.org/static/posters/creatures.png' },
			options: [
				{ title: 'Space Pirate', desc: 'More space battles!', img: 'https://vue-multiselect.js.org/static/posters/fleet.png' },
				{ title: 'Merchant', desc: 'PROFIT!', img: 'https://vue-multiselect.js.org/static/posters/trading_post.png' },
				{ title: 'Explorer', desc: 'Discovering new species!', img: 'https://vue-multiselect.js.org/static/posters/creatures.png' },
				{ title: 'Miner', desc: 'We need to go deeper!', img: 'https://vue-multiselect.js.org/static/posters/resource_lab.png' },
			],
			*/
			print,
			showMsgInfo,
			showMsgWarning,
			showMsgError,
			checkUserSession,
			settings,
			sleep,
			tablesData,
			tablesToBeRefreshed,
			getAllTablesData,
			getUserInfo,
			updateBranch,
			createPalletLabel,
			updatePrinter,
			executePrinterAction,
			backendUrl,
		}),
		async ionViewWillEnter() {
			this.setDefault();
			await this.getAllRequiredTables();
			this.assignCalculatedFields();
			this.isFirstLoad = false;
			this.isActive = true;
			/*
			window.addEventListener('beforeunload', (event) => {
				if (this.isActive || !this.isActive) {
					event.returnValue = 'You have unfinished changes!';
				}
			});
			*/
		},
		ionViewWillLeave() {
			this.isActive = false;
		},
		computed: {
			getPrintMode() {
				return this.fields[this.field.print_mode].value;
			},
		},
		watch: {
			getPrintMode() {
				if (this.fields[this.field.print_mode].value) {
					this.createSSCC();
				}
			},
		},
		methods: {
			//Settings
			getPlaceHolder(index) {
				return ((this.settings.showFieldsPlaceholder) ? (this.fields[index].required ? this.fields[index].placeholder : this.unselected ) : '')
			},
			customDescription ({ description, details, packages }) {
				return description + (details ? details : "") + (packages ? packages : "")
			},
			customLabel ({ name }) {
				return name
			},
			/*
			customImage ({ label_image_url }) {
				return this.backendUrl + label_image_url
			},
			*/
			//Database
			async getAllRequiredTables() {
				this.tablesToBeRefreshed.branches = true;
				this.tablesToBeRefreshed.printers = true;
				this.tablesToBeRefreshed.printTemplates = true;
				this.tablesToBeRefreshed.items = true;
				this.tablesToBeRefreshed.labelImages = true;
				if (this.branchId) {
					await this.getAllTablesData(this.branchId);
				}
				else {
					await this.getAllTablesData(null);
				}
			},
			async getPrinter() {
				/*
				if (this.settings.printerManagement) {
					let userInfo = this.getUserInfo();
					if (!userInfo || !userInfo.branch_id) {
						let currSearch = {
							params: {
								'q[default_eq]': true,
							}
						}
						this.searchData("printers", currSearch)
						.then(data => {
							if (data.length>0) {
								this.printer_id = data[0].id
							}
							else {
								this.printer_id = null;
								this.confirmYesNo(this.messageTitlePrint, this.warningPrintConfirmMessage)
								.then((data) => {
									if (data == false) {
										if (!this.multipack) this.$router.push("/home");
										else this.$router.push(`/revision/` + this.header_id + `?code=` + this.header_code);
									}
								})
							}
						})
						.catch(error => { this.printer_id = null; this.checkUserSession(error, "warning"); });
					}
					else {
						this.printer_id = userInfo.plant.printer_id;
					}
				}
				else {
						this.printer_id = null;
				}
				*/
			},
			//Actions
			setDefault() {
				//await this.getPrinter()
				if (this.isFirstLoad) {
					this.branchId = this.getUserInfo().branch_id;
					this.fields.forEach(element => element.value = element.defaultValue);
					this.fields[this.field.print_mode].value = this.print_modes[this.print_mode.manual];
					this.fields.forEach(element => (element.required == true? (element.value == null ? element.validationState = false : element.validationState = true) : element.validationState = null));
				}
			},
			detectPrinterSelection(value) {
				this.fields[this.field.printer].value = value;
				this.fields[this.field.printer].validationState = true;
			},
			detectPrinterUnselection() {
				this.fields[this.field.printer].value = this.fields[this.field.printer].defaultValue;
				this.fields[this.field.printer].validationState = false;
			},
			detectPrintTemplateSelection(value) {
				this.fields[this.field.print_template].value = value;
				this.fields[this.field.print_template].validationState = true;
			},
			detectPrintTemplateUnselection() {
				this.fields[this.field.print_template].value = this.fields[this.field.print_template].defaultValue;
				this.fields[this.field.print_template].validationState = false;
			},
			detectLogoSelection(value) {
				this.fields[this.field.logo].value = value;
				this.fields[this.field.logo].validationState = true;
			},
			detectLogoUnselection() {
				this.fields[this.field.logo].value = this.fields[this.field.logo].defaultValue;
				this.fields[this.field.logo].validationState = false;
			},
			detectItemSelection(value) {
				this.updateItemContents(value);
				this.fields[this.field.item].value = value;
				this.fields[this.field.description].value = value;
				this.fields[this.field.item].validationState = true;
				this.fields[this.field.description].validationState = true;
			},
			detectItemUnselection() {
				this.fields[this.field.item].value = this.fields[this.field.item].defaultValue;
				this.fields[this.field.description].value = this.fields[this.field.description].defaultValue;
				this.fields[this.field.item].validationState = false;
				this.fields[this.field.description].validationState = false;
			},
			detectPrintModeSelection(value) {
				this.fields[this.field.print_mode].value = value;
				this.fields[this.field.print_mode].validationState = true;
			},
			detectPrintModeUnselection() {
				this.fields[this.field.print_mode].value = this.fields[this.field.print_mode].defaultValue;
				this.fields[this.field.print_mode].validationState = false;
			},
			changedQuantity(value) {
				this.fields[this.field.label_quantity].value = value;
				(!value || value.length == 0)? this.fields[this.field.label_quantity].validationState = false : this.fields[this.field.label_quantity].validationState = true
			},
			changedLot(value) {
				this.fields[this.field.lot].value = value;
				(!value || value.length == 0)? this.fields[this.field.lot].validationState = false : this.fields[this.field.lot].validationState = true
			},
			changedProductionDate(value) {
				this.fields[this.field.production_date].value = value;
				(!value || value.length == 0)? this.fields[this.field.production_date].validationState = false : this.fields[this.field.production_date].validationState = true
				this.assignCalculatedFields();
			},
			changedSscc(value) {
				this.fields[this.field.sscc].value = value;
				(!value || value.length == 0)? this.fields[this.field.sscc].validationState = false : this.fields[this.field.sscc].validationState = true
			},
			changedItemWeight(value) {
				this.itemContents[this.itemContent.weight].value = value;
				this.fields[this.field.item].value.weight = value;
			},
			changedItemQuantity(value) {
				this.itemContents[this.itemContent.quantity].value = value;
				this.fields[this.field.item].value.quantity = value;
			},
			updateItemContents(row) {
				Object.getOwnPropertyNames(row).forEach(rowField => {
					this.itemContents.forEach(pageElement => {
						if (rowField != "id" && rowField == pageElement.name) {
							pageElement.value = row[rowField];
						}
					});
				});
			},
			openModal() {
				this.isOpenModal = true;
			},
			closeModal(enablePrint) {
				this.isPrinting = enablePrint;
				if (!enablePrint) this.assignCalculatedFields();
				this.isOpenModal = false;
			},
			async modalDismissed() {
				if (this.isPrinting == true) {
					await this.executePrint();
					this.isPrinting = false;
				}
			},
			checkFields() {
				let ret = true;
				this.fields.forEach(element => {if (element.required && !element.value) ret = false});
				if (!this.fields[this.field.label_quantity].value || this.fields[this.field.label_quantity].value <= 0) ret = false;
				return ret;
			},
			createLotto() {
				if (this.tablesData.currentBranch) {
					let currDate = new Date(this.fields[this.field.production_date].value);
					this.fields[this.field.lot].value = this.tablesData.currentBranch.lot_prefix;
					this.fields[this.field.lot].value += Math.floor((currDate - new Date(currDate.getFullYear().toString(), 0, 0)) / (1000 * 60 * 60 * 24)).toString().padStart(3, '0');
					this.fields[this.field.lot].value += currDate.getFullYear().toString().substring(2,4);
					this.fields[this.field.lot].value += this.tablesData.currentBranch.lot_branch;
					this.fields[this.field.lot].value += this.tablesData.currentBranch.turn;
					this.fields[this.field.lot].value += this.tablesData.currentBranch.production_line;
				}
				else {
					this.fields[this.field.lot].value = null;
				}
			},
			createSSCC() {
				if (this.tablesData.currentBranch) {
					let currDate = new Date(this.fields[this.field.production_date].value);
					this.fields[this.field.sscc].value = new Date(currDate).getFullYear().toString().substring(3,4);
					this.fields[this.field.sscc].value += this.tablesData.currentBranch.company_prefix;
					this.fields[this.field.sscc].value += this.tablesData.currentBranch.sscc_branch;
					this.fields[this.field.sscc].value += this.fields[this.field.print_mode].value.value;
					this.fields[this.field.sscc].value += this.tablesData.currentBranch.progressive.toString().padStart(6, '0');
					this.fields[this.field.sscc].value += this.calculateCheckDigit(this.fields[this.field.sscc].value);
				}
				else {
					this.fields[this.field.sscc].value = null;
				}
			},
			calculateCheckDigit(srcData) {
				let chkDigit = 0;
				for (let i = 0; i < (srcData.length); i = i + 2) {
					chkDigit += Number(srcData.substring(i, i + 1));
				}
				chkDigit = chkDigit * 3;
				for (let i = 1; i < (srcData.length); i = i + 2) {
					chkDigit += Number(srcData.substring(i, i + 1));
				}
				chkDigit = 10 - (chkDigit % 10);
				if (chkDigit == 10) { chkDigit = 0; }
				return chkDigit.toString();
			},
			checkPrinter() {
			},
			async cancelQueue(showMessage) {
				this.undoPrint = true;
				this.isPrinting = false;
				await this.executePrinterAction(`printers/` + this.fields[this.field.printer].value.id + `?do=cancel_print_queue`)
				.then(() => {
					if (showMessage) {
						this.showMsgInfo(this.messageTitleCancelQueue,this.successCancelQueue);
					}
				})
				.catch(error => {
					this.checkUserSession(error, "warning");
					this.showMsgError(this.messageTitleCancelQueue, this.errorCancelQueue);
				})
			},
			async downloadImageOnPrinter(showMessage) {
				await this.executePrinterAction(`printers/` + this.fields[this.field.printer].value.id + `?do=send_image` + `&image_id=` + this.fields[this.field.logo].value.id)
				.then(() => {
					if (showMessage) {
						this.showMsgInfo(this.messageTitleSendLogo,this.successSendLogo);
					}
				})
				.catch(error => {
					this.checkUserSession(error, "warning");
					this.showMsgError(this.messageTitleSendLogo, this.errorSendLogo);
				})
			},
			async printLabel(isLastLabel) {
				let printRequest = `printers/` + this.fields[this.field.printer].value.id + `?do=print_with_status` +
					`&darkness=` + this.fields[this.field.printer].value.temperature +
					`&label_id=` + this.tablesData.currentPalletLabel.id +
					`&print_mode=` + this.fields[this.field.print_mode].value.rotation +
					`&logo=` + this.fields[this.field.logo].value.filename.toUpperCase() +
					`&code=` + this.fields[this.field.item].value.code +
					`&today_date=` + new Date().toLocaleDateString() +
					`&today_time=` + new Date().toLocaleTimeString('it-IT').substring(0,5) +
					`&description=` + this.fields[this.field.item].value.description +
					`&details=` + (this.fields[this.field.item].value.details? this.fields[this.field.item].value.details : "") +
					`&packages=` + (this.fields[this.field.item].value.packages? this.fields[this.field.item].value.packages : "") +
					`&sscc=` + this.fields[this.field.sscc].value +
					`&content=` + this.fields[this.field.item].value.barcode +
					`&lot=` + this.fields[this.field.lot].value +
					`&count=` + this.fields[this.field.item].value.quantity +
					`&weight_string=` + Number(this.fields[this.field.item].value.weight).toString() +
					`&item_date_desciption=` + "PROD. DATE" +
					`&item_date_value=` + new Date(this.fields[this.field.production_date].value).toLocaleDateString() +
					`&itf=` + this.fields[this.field.item].value.barcode.padStart(14, '0') +
					`&qty=` + this.fields[this.field.item].value.quantity +
					`&net_weight=` + Number(this.fields[this.field.item].value.weight).toString().padStart(6, '0') +
					`&item_date_bcode_pos=` + "120" +
					`&item_date_text_pos=` + "650" +
					`&item_date_bcode_ai=` + "11" + this.fields[this.field.production_date].value.replaceAll("-","").substring(2,8) +
					`&item_date_text_ai=` + "(11)" + this.fields[this.field.production_date].value.replaceAll("-","").substring(2,8);
				await this.executePrinterAction(printRequest)
				.then(() => {
					if (isLastLabel == true) this.showMsgInfo(this.labelPrint,this.successPrint);
				})
				.catch(error => {
					this.checkUserSession(error, "warning");
					this.showMsgError(this.labelPrint, this.errorPrint);
				})
			},
			async executePrint() {
				await this.sendPrintTemplateToPrinter();
				await this.downloadImageOnPrinter(false);
				// await this.sleep(200);
				for (let i = 1; i <= this.fields[this.field.label_quantity].value; i += 1) {
					if (this.undoPrint == true) {
						this.undoPrint = false;
						this.showMsgWarning(this.labelPrint, this.userCancelPrint);
						break;
					}
					await this.createNewLabel();
					if (i == this.fields[this.field.label_quantity].value) {
						await this.printLabel(true);
					}
					else {
						await this.printLabel(false);
					}
					await this.updateBranchValues();
					// await this.sleep(200);
					this.createSSCC();
					// await this.sleep(200);
				}
				this.refreshPage();
			},
			refreshPage() {
				this.fields[this.field.production_date].value = this.fields[this.field.production_date].defaultValue;
				this.assignCalculatedFields();
				this.fields[this.field.label_quantity].value = null;
			},
			assignCalculatedFields() {
				this.fields.forEach(element => (element.required == true? (element.value == null ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.createLotto();
				this.createSSCC();
			},
			async sendPrintTemplateToPrinter() {
				let myPrinter = this.fields[this.field.printer].value.id;
				let myRecord = {
					printers: {
						print_template_id: this.fields[this.field.print_template].value.id,
					}
				};
				await this.updatePrinter(myPrinter, myRecord);
			},
			async createNewLabel() {
				let myRecord = {
					labels: {
						is_online: this.fields[this.field.print_mode].value.id,
						item_id: this.fields[this.field.item].value.id,
						print_template_id: this.fields[this.field.print_template].value.id,
						sscc: this.fields[this.field.sscc].value,
						lot: this.fields[this.field.lot].value,
						production_date: this.fields[this.field.production_date].value,
					}
				};
				await this.createPalletLabel(myRecord);
			},
			async updateBranchValues() {
				if (this.tablesData.currentBranch) {
					let myRecord = {
						branches: {
							progressive: this.tablesData.currentBranch.progressive + 1
						}
					};
					await this.updateBranch(this.branchId,myRecord);
				}
			},
		},
	});
</script>
