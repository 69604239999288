import { checkUserSession, settings } from "../plugins/common.js";
import { deleteData, getData, readData, searchQueryData, updateData, writeData } from "./database.js";

export var tablesData = {
	currentItem: null,
	currentBranch: null,
	currentPalletLabel: null,
	currentLotLabel: null,
	currentPrinter: null,
	printerActionResult: null,
	branches: [],
	locations: [],
	measureUnits: [],
	items: [],
	printers: [],
	printTemplates: [],
	labels: [],
	labelImages: [],
	activities: [],
	systemLogs: [],
}
export var tablesToBeRefreshed = {
	branches: false,
	locations: false,
	measureUnits: false,
	items: false,
	printers: false,
	printTemplates: false,
	labels: false,
	labelImages: false,
	activities: false,
	systemLogs: false,
}

export async function hasPrinter() {
	await getData("printers")
	.then(data => {
		(data.length > 0) ? settings.printerManagement = true : settings.printerManagement = false;
		this.tablesData.printers = data;
		return true;
	})
	.catch(error => {
		checkUserSession(error, "warning");
		return false;
	});
}
export async function getBranches() {
	await getData("branches")
	.then(data => { tablesData.branches = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getLocations() { 
	await getData("locations")
	.then(data => { tablesData.locations = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getMeasureUnits() {
	await getData("measure_units")
	.then(data => { tablesData.measureUnits = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getItems() {
	await getData("items")
	.then(data => { tablesData.items = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getPrinters() {
	await getData("printers")
	.then(data => { tablesData.printers = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getPrintTemplates() {
	await getData("print_templates")
	.then(data => { tablesData.printTemplates = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getLabels() {
	await getData("labels")
	.then(data => { tablesData.labels = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getLabelImages() {
	await getData("label_images")
	.then(data => { tablesData.labelImages = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getActivities() {
	await getData("activities")
	.then(data => { tablesData.activities = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getSystemLogs() {
	await getData("system_logs")
	.then(data => { tablesData.systemLogs = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function createItem(item_data) {
	await writeData("items", item_data)
	.then(data => {	tablesData.currentItem = data;	return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function createPalletLabel(label_data) {
	await writeData("labels", label_data)
	.then(data => {	tablesData.currentPalletLabel = data;	return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function createLotLabel(label_data) {
	await writeData("labels", label_data)
	.then(data => {	tablesData.currentLotLabel = data;	return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getItem(item_id) {
	await readData("items", item_id)
	.then(data => { tablesData.currentItem = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getBranch(branch_id) {
	await readData("branches", branch_id)
	.then(data => { tablesData.currentBranch = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function updateItem(item_id, item_data) {
	await updateData("items", item_id, item_data)
	.then(data => {	tablesData.currentItem = data;	return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function updateBranch(branch_id, branch_data) {
	await updateData("branches", branch_id, branch_data)
	.then(data => {	tablesData.currentBranch = data;	return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function updatePrinter(printer_id, printer_data) {
	await updateData("printers", printer_id, printer_data)
	.then(data => {	tablesData.currentPrinter = data;	return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function deleteItem(item_id) {
	await deleteData("items", item_id)
	.then(async () => {	await getItems();	return true; })
	.catch(error => { checkUserSession(error, "warning"); return false; });	
}
export async function searchPrinter(branch_id) {
	let mySearch = {
		params: {
			'q[branch_id_eq]': branch_id,
		}
	};
	await searchQueryData("printers", mySearch)
	.then(data => { tablesData.printers = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function executePrinterAction(destinationUrl) {
	await getData(destinationUrl)
	.then(data => {
		tablesData.printerActionResult = data;
		tablesData.printerIsOnErrorState = false;
		return true;
	})
	/*
	.catch(error => {
		checkUserSession(error, "warning");
		return false;
	});
	*/
}
export async function getAllTablesData(branch_id) {
	(branch_id)? await getBranch(branch_id) : tablesData.currentBranch = null;
	if (tablesToBeRefreshed.branches) await getBranches();
	if (tablesToBeRefreshed.locations) await getLocations();
	if (tablesToBeRefreshed.measureUnits) await getMeasureUnits();
	if (tablesToBeRefreshed.items) await getItems();
	if (tablesToBeRefreshed.printers) (branch_id)? await searchPrinter(branch_id) : await getPrinters();
	if (tablesToBeRefreshed.printTemplates) await getPrintTemplates();
	if (tablesToBeRefreshed.labels) await getLabels();
	if (tablesToBeRefreshed.labelImages) await getLabelImages();
	if (tablesToBeRefreshed.activities) await getActivities();
	if (tablesToBeRefreshed.systemLogs) await getSystemLogs();
}
