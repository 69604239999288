<template>
	<ion-page>
		<spot-title :title="pageTitle"></spot-title>
		<ion-content :fullscreen="true">
			<ion-progress-bar type="indeterminate" v-if="!isActive"></ion-progress-bar>
			<div v-if="isActive">
				<ion-item>
					<spot-input
						:ref="fields[field.search].name"
						:label="fields[field.search].text"
						:allignLabel="fields[field.search].allignLabel"
						:required="fields[field.search].required"
						:placeholder="fields[field.search].placeholder"
						:enabled="true"
						:value="fields[field.search].value"
						:type="fields[field.search].inputType"
						:clearInput="true"
						@textChanged="changedSearch">
					</spot-input>
					<ion-button size="default" slot="end" @click="createNewItem">
						<ion-icon :icon="duplicate" slot="start" size="default"></ion-icon>
						{{ newItemText }}
					</ion-button>
				</ion-item>
				<ion-progress-bar type="indeterminate" v-if="!tablesData.items"></ion-progress-bar>
				<EasyDataTable
					:headers="headers"
					:items="tablesData.items"
					:sort-by="sortBy"
					:sort-type="sortType"
					:search-field="searchFields"
					:search-value="fields[field.search].value"
					:empty-message="noItemsText"
					multi-sort
					alternating
					show-index
				>
					<template #item-expiration="{ expiration }">
						<ion-checkbox :checked="expiration" disabled="true"></ion-checkbox>
					</template>
					<template #item-month_end="{ month_end }">
						<ion-checkbox :checked="month_end" disabled="true"></ion-checkbox>
					</template>
					<template #item-actions="item">
						<ion-button disabled=false fill="outline" shape="round" size="small" slot="icon-only" @click="editExistingItem(item)">
							<ion-icon :icon="brush" slot="end" size="small"></ion-icon>
						</ion-button>
						<ion-button disabled=true fill="outline" shape="round" size="small" slot="icon-only" @click="deleteExistingItem(item)">
							<ion-icon :icon="trash" slot="end" size="small"></ion-icon>
						</ion-button>
					</template>
				</EasyDataTable>
				<ion-modal ref="modal" backdrop-dismiss="false" :is-open="isOpenModal" @didDismiss="modalDismissed">
					<ion-content>
						<ion-toolbar>
							<ion-title> {{ modalTitle }}</ion-title>
						</ion-toolbar>
						<ion-card disabled="false">
							<ion-card-content class="flex-column-left-container">
								<ion-item lines="none" v-for="field in itemContents" :key="field.id">
									<spot-input
										v-if="field.inputType == 'text' || field.inputType == 'number'"
										:ref="field.name"
										:label="field.text"
										:required="field.required"
										:placeholder="getPlaceHolder(field)"
										:enabled="field.id >= 1 ? true : false"
										:validationState="field.validationState"
										:value="field.value"
										:type="field.inputType"
										@textChanged="changedTextField(field.id, $event)">
									</spot-input>
									<spot-checkbox
										v-if="field.inputType == 'check'"
										:ref="field.name"
										:label="field.text"
										:required="field.required"
										:enabled="field.id >= 1 ? true : false"
										:validationState="field.validationState"
										:value="field.value"
										@checkboxChanged="changedCheckboxField(field.id, $event)">
									</spot-checkbox>
								</ion-item>
							</ion-card-content>
						</ion-card>
						<div class="flex-row-center-container">
							<spot-button
								fill="outline"
								size="large"
								color="primary"
								:enabled="true"
								:expand="null"
								:text="undoText"
								@clicked="closeModal(false)">
							</spot-button>
							<spot-button
								fill="outline"
								size="large"
								color="primary"
								:enabled="!isEmptyRequiredFields"
								:expand="null"
								:text="confirmText"
								@clicked="closeModal(true)">
							</spot-button>
						</div>
					</ion-content>
				</ion-modal>
			</div>
		</ion-content>
 </ion-page>
</template>

<style scoped>
	ion-modal {
		--height: 80%;
		--width: 50%;
		--border-radius: 16px;
		--box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	}
	ion-modal::part(backdrop) {
		background: var(--ion-color-medium);
		opacity: 1;
	}
	ion-modal ion-toolbar {
		--background: var(--ion-color-primary);
		--color: white;
	}
	.flex-column-left-container {
		margin-top: 0.2em;
		margin-bottom: 0.2em;
		text-align: left;
		display: flex;
		flex-direction: column;
		/*justify-content: left;*/
		/*align-items: left;*/
		flex-wrap: wrap;
	}
	.flex-row-center-container {
		margin-top: 1em;
		text-align: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
</style>

<script>
import { IonButton, IonCard, IonCardContent, IonCheckbox, IonContent, IonIcon, IonItem, IonModal, IonPage, IonProgressBar, IonTitle, IonToolbar } from '@ionic/vue';
import { brush, duplicate, trash } from 'ionicons/icons';
import { defineComponent } from 'vue';
import SpotButton from '../components/SpotButton.vue';
import SpotCheckbox from '../components/SpotCheckbox.vue';
import SpotInput from '../components/SpotInput.vue';
import SpotTitle from '../components/SpotTitle.vue';
import { confirmYesNo, settings } from '../plugins/common.js';
import { createItem, deleteItem, getItems, tablesData, updateItem } from '../plugins/customDatabase.js';

	export default defineComponent({
		name: 'ItemsView',
		props: {
			pageTitle: { type: String, default: 'Visualizza Anagrafica Articoli' },
			noItemsText: { type: String, default: 'Nessun Articolo presente' },
			newItemText: { type: String, default: 'Nuovo Articolo' },
			editItemText: { type: String, default: 'Modifica Articolo: '},
			undoText: { type: String, default: 'Annulla' },
			confirmText: { type: String, default: 'Conferma' },
			unselected: { type: String, default: 'Nessuno' },
			confirmDeleteTitle: { type: String, default: 'Conferma Cancellazione' },
			confirmDeleteMessage: { type: String, default: 'Confermi la cancellazione dell\'articolo corrente?' },
		},
		components: {
			IonPage,
			IonContent,
			IonProgressBar,
			SpotTitle,
			IonItem,
			IonButton,
			IonIcon,
			SpotInput,
			SpotCheckbox,
			SpotButton,
			IonModal,
			IonToolbar,
			IonTitle,
			IonCard,
			IonCardContent,
			IonCheckbox,
		},
		data: () => ({
			isActive: null,
			field: {
				search: 0,
			},
			fields: [
				{ id: 0, name: 'search', text: 'Cerca: ', allignLabel: true, placeholder: 'Cerca Articolo', inputType: 'text', defaultValue: '', value: null, required: false, refField: null },
			],
			searchFields: ['code', 'description', 'details', 'packages', 'barcode'],
			headers: [
				{ text: 'Codice', value: 'code', sortable: true },
				{ text: 'Descrizione', value: 'description', sortable: true },
				{ text: 'Dettaglio', value: 'details', sortable: true },
				{ text: 'Imballo', value: 'packages', sortable: true },
				{ text: 'EAN 13 / ITF 14', value: 'barcode', sortable: true },
				{ text: 'Peso (Kg)', value: 'weight', sortable: true },
				{ text: 'Quantità', value: 'quantity', sortable: true },
				{ text: 'Scadenza', value: 'expiration', sortable: false },
				{ text: 'Fine Mese', value: 'month_end', sortable: false },
				{ text: 'Durabilità', value: 'durability', sortable: false },
				{ text: 'Azioni', value:'actions', sortable: false }
			],
			sortType: ["desc", "asc"],
			sortBy: ['code'],
			itemContent: {
				id: 0,
				code: 1,
				description: 2,
				details: 3,
				packages: 4,
				barcode: 5,
				weight: 6,
				quantity: 7,
				expiration: 8,
				month_end: 9,
				durability: 10,
			},
			itemContents: [
				{ id: 0, name: 'id', text: 'Id', placeholder: 'Inserisci Articolo', inputType: "number", validationState: null, defaultValue: null, value: null, required: true },
				{ id: 1, name: 'code', text: 'Codice', placeholder: 'Inserisci Codice', inputType: "text", validationState: null, defaultValue: null, value: null, required: true },
				{ id: 2, name: 'description', text: 'Descrizione', placeholder: 'Inserisci Descrizione', inputType: "text", validationState: null, defaultValue: null, value: null, required: true },
				{ id: 3, name: 'details', text: 'Dettagli', placeholder: 'Inserisci Dettagli', inputType: "text", validationState: null, defaultValue: null, value: null, required: false },
				{ id: 4, name: 'packages', text: 'Imballaggio', placeholder: 'Inserisci Imballaggio', inputType: "text", validationState: null, defaultValue: null, value: null, required: false },
				{ id: 5, name: 'barcode', text: 'EAN 13 | ITF 14', placeholder: 'Inserisci Barcode', inputType: "text", validationState: null, defaultValue: null, value: null, required: true },
				{ id: 6, name: 'weight', text: 'Peso', placeholder: 'Inserisci Peso (Kg)', inputType: "number", validationState: null, defaultValue: null, value: null, required: true },
				{ id: 7, name: 'quantity', text: 'Quantità', placeholder: 'Inserisci Quantità', inputType: "number", validationState: null, defaultValue: null, value: null, required: true },
				{ id: 8, name: 'expiration', text: 'Scadenza', inputType: "check", validationState: null, defaultValue: false, value: null, required: false },
				{ id: 9, name: 'month_end', text: 'Fine Mese', inputType: "check", validationState: null, defaultValue: false, value: null, required: false },
				{ id: 10, name: 'durability', text: 'Durabilità', placeholder: 'Inserisci Durabilità', inputType: "number", validationState: null, defaultValue: null, value: null, required: false },
			],
			isOpenModal: false,
			modalTitle: null,
			actionDbCreate: null,
			duplicate,
			trash,
			brush,
			settings,
			confirmYesNo,
			getItems,
			createItem,
			updateItem,
			deleteItem,
			tablesData,
		}),
		async ionViewWillEnter() {
			this.setDefault();
			await this.getAllRequiredTables();
			this.isActive = true;
		},
		ionViewWillLeave() {
			this.isActive=false;
		},
		computed: {
			isEmptyRequiredFields() {
				let ret = false;
				this.itemContents.forEach(element => {if (element.required && !element.value && element.id > 0) ret=true});
				return ret;
			},
		},
		methods: {
			//Settings
			setDefault() {
				this.fields.forEach(element => element.value = element.defaultValue);
				this.isOpenModal = false;
				this.modalTitle = null;
				this.actionDbCreate = null;
			},
			getPlaceHolder(element) {
				return ((this.settings.showFieldsPlaceholder) ? (element.required ? element.placeholder : this.unselected ) : '')
			},
			//Database
			async getAllRequiredTables() {
				this.tablesData.items = [];
				await this.getItems();
			},
			async deleteExistingItem(item) {
				let canDelete = false;
				await this.confirmYesNo(this.confirmDeleteTitle, this.confirmQuantityMessage)
					.then((data) => { canDelete = data })
				if (canDelete == true) {
					await this.deleteItem(item.id)
				}
			},
			//Actions
			changedSearch(value) {
				this.fields[this.field.search].value = value;
			},
			changedTextField(fieldId, value) {
				this.itemContents[fieldId].value = value;
				(!value || value.length == 0)? this.itemContents[fieldId].validationState = false : this.itemContents[fieldId].validationState = true
			},
			changedCheckboxField(fieldId, value) {
				this.itemContents[fieldId].value = value;
				(!value || value.length == 0)? this.itemContents[fieldId].validationState = false : this.itemContents[fieldId].validationState = true
			},
			prepareRecord() {
				let myRecord = {
					item: {
						code: this.itemContents[this.itemContent.code].value,
						description: this.itemContents[this.itemContent.description].value,
						details: this.itemContents[this.itemContent.details].value,
						packages: this.itemContents[this.itemContent.packages].value,
						barcode: this.itemContents[this.itemContent.barcode].value,
						weight: this.itemContents[this.itemContent.weight].value,
						quantity: this.itemContents[this.itemContent.quantity].value,
						expiration: this.itemContents[this.itemContent.expiration].value,
						month_end: this.itemContents[this.itemContent.month_end].value,
						durability: this.itemContents[this.itemContent.durability].value,
						location_id: 1,
						measure_unit_id: 3,
					}
				};
				return myRecord;
			},
			updateItemContents(row) {
				Object.getOwnPropertyNames(row).forEach(rowField => {
					this.itemContents.forEach(pageElement => {
						if (rowField == pageElement.name) {
							pageElement.value = row[rowField];
						}
					});
				});
			},
			createNewItem() {
				this.itemContents.forEach(element => element.value = element.defaultValue);
				this.itemContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.newItemText;
				this.actionDbCreate = true;
				this.openModal();
			},
			async editExistingItem(item) {
				this.updateItemContents(item);
				this.itemContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.editItemText + item.code;
				this.actionDbCreate = false;
				this.openModal();
			},
			openModal() {
				this.isOpenModal = true;
			},
			async closeModal(saveRecord) {
				if (saveRecord == true) {
					if (this.actionDbCreate == true) {
						await this.createItem(this.prepareRecord())
					}
					else {
						await this.updateItem(this.itemContents[this.itemContent.id].value, this.prepareRecord())
					}
					await this.getAllRequiredTables();
				}
				this.isOpenModal = false;
			},
			async modalDismissed() {
				this.actionDbCreate == null;
			},
		},
	});
</script>
