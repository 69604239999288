import axios from "@/plugins/axiosApi";
import { publicAccess } from "@/plugins/variables.js";
import router from '@/router/index';

export default {
	namespaced: true,

	state: {
		token: null,
	},

	getters: {
		isAuthenticated: (state) => !!state.token,
		token: (state) => state.token,
	},

	mutations: {
		setToken: (state, payload) => {
			/*console.log(
				"Mutations.Get "
					.concat(localStorage.getItem("user-token"))
					.concat("\nMutations.Set ")
					.concat(JSON.stringify(payload))
			);*/
			// console.log(`PAYLOAD: ${payload}`)
			localStorage.setItem("user-token", JSON.stringify(payload));
			state.token = payload;
		},
		removeToken: (state) => {
			localStorage.removeItem("user-token");
			state.token = null;
		},
	},

	actions: {
		login: ({
			commit
		}, {
			model,
			data
		}) => {
			commit("removeToken");
			return axios
				.post(`/${model}`, data, {
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
				})
				.then((response) => {
					/*console.log("Login OK ".concat(response.headers.token));*/
					if (response.headers.token) commit("setToken", response.headers.token);
					//return Promise.resolve();
					return response.data;
				})
				.catch((error) => {
					commit("removeToken");
					return Promise.reject(error.response);
				});
		},
		setTokenFromLocalStorage: ({
			commit
		}) => {
			let token = localStorage.getItem("user-token") || null;
			commit("setToken", JSON.parse(token));
		},
		logout: ({
			commit
		}) => {
			commit("removeToken");
		},
		fetch: ({
			commit
		}, {
			model,
			updateToken = true
		}) => {
			/*console.log(
				"fetch "
					.concat(model)
					.concat("\nrg: ")
					.concat(rootGetters["tables/token"])
					.concat("\nls: ")
					.concat(localStorage.getItem("user-token"))
			);*/
			var tk = localStorage.getItem("user-token")
			// console.log(tk)
			// console.log(typeof tk)
			if (tk && tk !== "undefined") var myToken = JSON.parse(tk);
			else if(publicAccess) router.push("/");
			else router.push("/login");
			const newUrl = model.toString().includes("?") ?
				`/${model}&cb=${Date.now()}` :
				`/${model}?cb=${Date.now()}`;
			return axios
				.get(newUrl /*`/${model}}`*/ , {
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						Authorization: `Bearer ${myToken}`,
					},
				})
				.then((response) => {
					if (updateToken === true && response.headers.token) commit("setToken", response.headers.token);
					/*console.log(
						"fetch "
							.concat(model)
							.concat(" OK ")
							.concat("\nht: ")
							.concat(response.headers.token)
							.concat("\nrg: ")
							.concat(rootGetters["tables/token"])
							.concat("\nls: ")
							.concat(localStorage.getItem("user-token"))
					);*/
					return response.data;
				})
				.catch((error) => {
					if (error && error.response && error.response.status === 404 && error.response.data && !error.response.data.error) {
						return error.response.data; //return null;
					} else {
						return Promise.reject(error.response);
					}
				})
		},
		create: ({
			commit
		}, {
			model,
			data,
			updateToken = true
		}) => {
			/*console.log(
				"create "
					.concat(model)
					.concat("\nrg: ")
					.concat(rootGetters["tables/token"])
					.concat("\nls: ")
					.concat(localStorage.getItem("user-token"))
			);*/
			var tk = localStorage.getItem("user-token")
			// console.log(tk)
			// console.log(typeof tk)
			if (tk && tk !== "undefined") var myToken = JSON.parse(tk);
			else if(publicAccess) router.push("/");
			else router.push("/login");
			return axios
				.post(`/${model}`, data, {
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						Authorization: `Bearer ${myToken}`,
					},
				})
				.then((response) => {
					if (updateToken === true && response.headers.token) commit("setToken", response.headers.token);
					/*console.log(
						"create "
							.concat(model)
							.concat(" OK ")
							.concat("\nht: ")
							.concat(response.headers.token)
							.concat("\nrg: ")
							.concat(rootGetters["tables/token"])
							.concat("\nls: ")
							.concat(localStorage.getItem("user-token"))
					);*/
					return response.data;
				})
				.catch((error) => {
					return Promise.reject(error.response);
				});
		},
		createForm: ({
			commit
		}, {
			model,
			data,
			updateToken = true
		}) => {
			/*console.log(
				"create "
					.concat(model)
					.concat("\nrg: ")
					.concat(rootGetters["tables/token"])
					.concat("\nls: ")
					.concat(localStorage.getItem("user-token"))
			);*/
			var tk = localStorage.getItem("user-token")
			// console.log(tk)
			// console.log(typeof tk)
			if (tk && tk !== "undefined") var myToken = JSON.parse(tk);
			else if(publicAccess) router.push("/");
			else router.push("/login");
			return axios
				.post(`/${model}`, data, {
					headers: {
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
						Authorization: `Bearer ${myToken}`,
					},
				})
				.then((response) => {
					if (updateToken === true && response.headers.token) commit("setToken", response.headers.token);
					/*console.log(
						"create "
							.concat(model)
							.concat(" OK ")
							.concat("\nht: ")
							.concat(response.headers.token)
							.concat("\nrg: ")
							.concat(rootGetters["tables/token"])
							.concat("\nls: ")
							.concat(localStorage.getItem("user-token"))
					);*/
					return response.data;
				})
				.catch((error) => {
					return Promise.reject(error.response);
				});
		},
		read: ({
			commit
		}, {
			model,
			id,
			updateToken = true
		}) => {
			/*console.log(
				"read "
					.concat(model)
					.concat("\nrg: ")
					.concat(rootGetters["tables/token"])
					.concat("\nls: ")
					.concat(localStorage.getItem("user-token"))
			);*/
			var tk = localStorage.getItem("user-token")
			// console.log(tk)
			// console.log(typeof tk)
			if (tk && tk !== "undefined") var myToken = JSON.parse(tk);
			else if(publicAccess) router.push("/");
			else router.push("/login");
			return axios
				.get(`/${model}/${id}`, {
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						Authorization: `Bearer ${myToken}`,
					},
				})
				.then((response) => {
					if (updateToken === true && response.headers.token) commit("setToken", response.headers.token);
					/*console.log(
							"read "
								.concat(model)
								.concat(" OK ")
								.concat("\nht: ")
								.concat(response.headers.token)
								.concat("\nrg: ")
								.concat(rootGetters["tables/token"])
								.concat("\nls: ")
								.concat(localStorage.getItem("user-token"))
						);*/
					return response.data;
				})
				.catch((error) => {
					return Promise.reject(error.response);
				})
		},
		update: ({
			commit
		}, {
			model,
			id,
			data,
			updateToken = true
		}) => {
			/*console.log(
				"update "
					.concat(model)
					.concat("\nrg: ")
					.concat(rootGetters["tables/token"])
					.concat("\nls: ")
					.concat(localStorage.getItem("user-token"))
			);*/
			var tk = localStorage.getItem("user-token")
			// console.log(tk)
			// console.log(typeof tk)
			if (tk && tk !== "undefined") var myToken = JSON.parse(tk);
			else if(publicAccess) router.push("/");
			else router.push("/login");
			return axios
				.put(`/${model}/${id}`, data, {
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						Authorization: `Bearer ${myToken}`,
					},
				})
				.then((response) => {
					if (updateToken === true && response.headers.token) commit("setToken", response.headers.token);
					/*console.log(
						"update "
							.concat(model)
							.concat(" OK ")
							.concat("\nht: ")
							.concat(response.headers.token)
							.concat("\nrg: ")
							.concat(rootGetters["tables/token"])
							.concat("\nls: ")
							.concat(localStorage.getItem("user-token"))
					);*/
					return response.data;
				})
				.catch((error) => {
					return Promise.reject(error.response);
				})
		},
		bulkUpdate: ({
			commit
		}, {
			model,
			id,
			data,
			updateToken = true
		}) => {
			/*console.log(
				"update "
					.concat(model)
					.concat("\nrg: ")
					.concat(rootGetters["tables/token"])
					.concat("\nls: ")
					.concat(localStorage.getItem("user-token"))
			);*/
			var tk = localStorage.getItem("user-token")
			// console.log(tk)
			// console.log(typeof tk)
			if (tk && tk !== "undefined") var myToken = JSON.parse(tk);
			else if(publicAccess) router.push("/");
			else router.push("/login");
			return axios
				.put(`/${model}/${id}?do=bulk_update_rows`, data, {
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						Authorization: `Bearer ${myToken}`,
					},
				})
				.then((response) => {
					if (updateToken === true && response.headers.token) commit("setToken", response.headers.token);
					/*console.log(
						"update "
							.concat(model)
							.concat(" OK ")
							.concat("\nht: ")
							.concat(response.headers.token)
							.concat("\nrg: ")
							.concat(rootGetters["tables/token"])
							.concat("\nls: ")
							.concat(localStorage.getItem("user-token"))
					);*/
					return response.data;
				})
				.catch((error) => {
					return Promise.reject(error.response);
				})
		},
		updateForm: ({
			commit
		}, {
			model,
			id,
			data,
			updateToken = true
		}) => {
			/*console.log(
				"update "
					.concat(model)
					.concat("\nrg: ")
					.concat(rootGetters["tables/token"])
					.concat("\nls: ")
					.concat(localStorage.getItem("user-token"))
			);*/
			var tk = localStorage.getItem("user-token")
			// console.log(tk)
			// console.log(typeof tk)
			if (tk && tk !== "undefined") var myToken = JSON.parse(tk);
			else if(publicAccess) router.push("/");
			else router.push("/login");
			return axios
				.put(`/${model}/${id}`, data, {
					headers: {
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
						Authorization: `Bearer ${myToken}`,
					},
				})
				.then((response) => {
					if (updateToken === true && response.headers.token) commit("setToken", response.headers.token);
					/*console.log(
						"update "
							.concat(model)
							.concat(" OK ")
							.concat("\nht: ")
							.concat(response.headers.token)
							.concat("\nrg: ")
							.concat(rootGetters["tables/token"])
							.concat("\nls: ")
							.concat(localStorage.getItem("user-token"))
					);*/
					return response.data;
				})
				.catch((error) => {
					return Promise.reject(error.response);
				})
		},
		bulkUpdateForm: ({
			commit
		}, {
			model,
			id,
			data,
			updateToken = true
		}) => {
			/*console.log(
				"update "
					.concat(model)
					.concat("\nrg: ")
					.concat(rootGetters["tables/token"])
					.concat("\nls: ")
					.concat(localStorage.getItem("user-token"))
			);*/
			var tk = localStorage.getItem("user-token")
			// console.log(tk)
			// console.log(typeof tk)
			if (tk && tk !== "undefined") var myToken = JSON.parse(tk);
			else if(publicAccess) router.push("/");
			else router.push("/login");
			return axios
				.put(`/${model}/${id}?do=bulk_update_signatures`, data, {
					headers: {
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
						Authorization: `Bearer ${myToken}`,
					},
				})
				.then((response) => {
					if (updateToken === true && response.headers.token) commit("setToken", response.headers.token);
					/*console.log(
						"update "
							.concat(model)
							.concat(" OK ")
							.concat("\nht: ")
							.concat(response.headers.token)
							.concat("\nrg: ")
							.concat(rootGetters["tables/token"])
							.concat("\nls: ")
							.concat(localStorage.getItem("user-token"))
					);*/
					return response.data;
				})
				.catch((error) => {
					return Promise.reject(error.response);
				})
		},
		delete: ({
			commit,
			rootGetters
		}, {
			model,
			id,
			updateToken = true
		}) => {
			return axios
				.delete(`/${model}/${id}`, {
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						Authorization: `Bearer ${rootGetters["tables/token"]}`,
					},
				})
				.then((response) => {
					if (updateToken === true && response.headers.token) commit("setToken", response.headers.token);
					return response.data;
				})
				.catch((error) => {
					return Promise.reject(error.response);
				})
		},
		querySearch: ({
			commit
		}, {
			model,
			data,
			updateToken = true
		}) => {
			/*console.log(
				"querySearch "
					.concat(model)
					.concat("\nrg: ")
					.concat(rootGetters["tables/token"])
					.concat("\nls: ")
					.concat(localStorage.getItem("user-token"))
			);*/
			let json = null;
			var tk = localStorage.getItem("user-token")
			// console.log(tk)
			// console.log(typeof tk)
			if (tk && tk !== "undefined") var myToken = JSON.parse(tk);
			else if(publicAccess) router.push("/");
			else router.push("/login");
			data.params["cb"] = Date.now();
			return axios
				.post(`/${model}/search`, json, {
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						Authorization: `Bearer ${myToken}`,
					},
					params: data.params,
				})
				.then((response) => {
					if (updateToken === true && response.headers.token) commit("setToken", response.headers.token);
					/*console.log(
						"querySearch "
							.concat(model)
							.concat(" OK ")
							.concat("\nht: ")
							.concat(response.headers.token)
							.concat("\nrg: ")
							.concat(rootGetters["tables/token"])
							.concat("\nls: ")
							.concat(localStorage.getItem("user-token"))
					);*/
					return response.data;
				})
				.catch((error) => {
					if (error && error.response && error.response.status === 404) { // && error.response.data && !error.response.data.error
						return error.response.data;
					} else {
						return Promise.reject(error.response);
					}
				});
		},
		jsonSearch: ({
			commit
		}, {
			model,
			data,
			updateToken = true
		}) => {
			/*console.log(
				"jsonSearch "
					.concat(model)
					.concat("\nrg: ")
					.concat(rootGetters["tables/token"])
					.concat("\nls: ")
					.concat(localStorage.getItem("user-token"))
			);*/
			var tk = localStorage.getItem("user-token")
			// console.log(tk)
			// console.log(typeof tk)
			if (tk && tk !== "undefined") var myToken = JSON.parse(tk);
			else if(publicAccess) router.push("/");
			else router.push("/login");
			data['cb'] = Date.now();
			return axios
				.post(`/${model}/search`, data, {
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						Authorization: `Bearer ${myToken}`,
					},
				})
				.then((response) => {
					if (updateToken === true && response.headers.token) commit("setToken", response.headers.token);
					/*console.log(
						"jsonSearch "
							.concat(model)
							.concat(" OK ")
							.concat("\nht: ")
							.concat(response.headers.token)
							.concat("\nrg: ")
							.concat(rootGetters["tables/token"])
							.concat("\nls: ")
							.concat(localStorage.getItem("user-token"))
					);*/
					return response.data;
				})
				.catch((error) => {
					if (error && error.response && error.response.status === 404) { // && error.response.data && !error.response.data.error
						return error.response.data;
					} else {
						return Promise.reject(error.response);
					}
				});
		},
		count: ({
			commit,
			rootGetters
		}, {
			model,
			data,
			updateToken = true
		}) => {
			data.params["count"] = "true";
			return axios
				.get(`/${model}`, {
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						Authorization: `Bearer ${rootGetters["tables/token"]}`,
					},
					params: data.params,
				})
				.then((response) => {
					if (updateToken === true && response.headers.token) commit("setToken", response.headers.token);
					return response.data;
				})
				.catch((error) => {
					return Promise.reject(error.response);
				})
		},
		getSchema: ({
			commit,
			rootGetters
		}, model) => {
			return axios
				.get(`/${model}`, {
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						Authorization: `Bearer ${rootGetters["tables/token"]}`,
					},
				})
				.then((response) => {
					if (response.headers.token) commit("setToken", response.headers.token);
					return response.data;
				})
				.catch((error) => {
					return Promise.reject(error.response);
				})
		},
		getDsl: ({
			commit,
			rootGetters
		}, model) => {
			return axios
				.get(`/${model}`, {
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						Authorization: `Bearer ${rootGetters["tables/token"]}`,
					},
				})
				.then((response) => {
					if (response.headers.token) commit("setToken", response.headers.token);
					return response.data;
				})
				.catch((error) => {
					return Promise.reject(error.response);
				})
		},
	},
};