<template>
	<ion-header>
		<ion-toolbar class="title">
			<ion-title>{{ title }}</ion-title>
		</ion-toolbar>
	</ion-header>
</template>

<style scoped>
	.title {
		display: flex;
		margin-top: 0em;
		margin-bottom: 0em;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		--padding-top: 0em;
		--padding-bottom: 0em;
	}
</style>

<script>
import { IonHeader, IonTitle, IonToolbar } from '@ionic/vue';
import { defineComponent } from "vue";

	export default defineComponent({
		props: {
			title: { type: String, default: "PageTitle" },
		},
		components: {
			IonHeader,
			IonToolbar,
			IonTitle,
		},
	});
</script>