import { createRouter, createWebHistory } from '@ionic/vue-router';
// import { RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
import ItemsView from "../views/ItemsView.vue";
import Login from "../views/Login.vue";
import LottoPrint from "../views/LottoPrint.vue";
import PalletPrint from "../views/PalletPrint.vue";
import Root from "../views/Root.vue";

const routes = [
	{
		path: "/",
		name: "Root",
		component: Root,
	},
	{
		path: "/login",
		name: "Login",
		component: Login,
	},
	{
		path: '/home',
		name: 'Home',
		component: Home,
		children: [
			{
				path: "/palletPrint", // "/palletPrint/:type"
				name: "PalletPrint",
				component: PalletPrint,
			},
			{
				path: "/lottoPrint", // "/lottoPrint/:type"
				name: "LottoPrint",
				component: LottoPrint,
			},
			{
				path: "/itemsView", // "/itemsView/:type"
				name: "ItemsView",
				component: ItemsView,
			},
		]
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
