<template>
	<ion-page>
		<spot-title :title="pageTitle"></spot-title>
		<ion-content :fullscreen="true">
			<ion-progress-bar type="indeterminate" v-if="!isActive"></ion-progress-bar>
			<div class="flex-row-center-container" v-if="isActive">
				<spot-select
					:ref="fields[field.printer].name"
					:label="fields[field.printer].text"
					:allignLabel="fields[field.printer].allignLabel"
					:required="fields[field.printer].required"
					:placeholder="getPlaceHolder(field.printer)"
					:enabled="!isPrinting"
					:validationState="fields[field.printer].validationState"
					:value="fields[field.printer].value"
					:options="tablesData.printers"
					:optionField="fields[field.printer].refField"
					selectedLabel="Selezionato"
					deselectLabel="rimuovi"
					selectLabel="seleziona"
					@selected="detectPrinterSelection"
					@unselected="detectPrinterUnselection">
				</spot-select>
				<spot-select
					:ref="fields[field.print_template].name"
					:label="fields[field.print_template].text"
					:allignLabel="fields[field.print_template].allignLabel"
					:required="fields[field.print_template].required"
					:placeholder="getPlaceHolder(field.print_template)"
					:enabled="!isPrinting"
					:validationState="fields[field.print_template].validationState"
					:value="fields[field.print_template].value"
					:options="tablesData.printTemplates"
					:optionField="fields[field.print_template].refField"
					selectedLabel="Selezionato"
					deselectLabel="rimuovi"
					selectLabel="seleziona"
					@selected="detectPrintTemplateSelection"
					@unselected="detectPrintTemplateUnselection">
				</spot-select>
				<spot-date
					:ref="fields[field.production_date].name"
					:label="fields[field.production_date].text"
					:allignLabel="fields[field.production_date].allignLabel"
					:required="fields[field.production_date].required"
					:enabled="!isPrinting"
					:validationState="fields[field.production_date].validationState"
					:value="fields[field.production_date].value"
					color="primary"
					doneText="Conferma"
					cancelText="Annulla"
					@dateChanged="changedProductionDate">
				</spot-date>
			</div>
			<div class="flex-row-center-container" v-if="isActive">
				<spot-select
					:ref="fields[field.item].name"
					:label="fields[field.item].text"
					:allignLabel="fields[field.item].allignLabel"
					:required="fields[field.item].required"
					:placeholder="getPlaceHolder(field.item)"
					:enabled="!isPrinting"
					:validationState="fields[field.item].validationState"
					:value="fields[field.item].value"
					:options="tablesData.items"
					:optionField="fields[field.item].refField"
					selectedLabel="Selezionato"
					deselectLabel="rimuovi"
					selectLabel="seleziona"
					@selected="detectItemSelection"
					@unselected="detectItemUnselection">
				</spot-select>
				<spot-select-custom
					:ref="fields[field.description].name"
					:label="fields[field.description].text"
					:allignLabel="fields[field.description].allignLabel"
					:required="fields[field.description].required"
					:placeholder="getPlaceHolder(field.description)"
					:enabled="!isPrinting"
					:validationState="fields[field.description].validationState"
					:value="fields[field.description].value"
					:options="tablesData.items"
					:optionField="fields[field.description].refField"
					:custom-label="customDescription"
					selectedLabel="Selezionato"
					deselectLabel="rimuovi"
					selectLabel="seleziona"
					@selected="detectItemSelection"
					@unselected="detectItemUnselection">
				</spot-select-custom>
			</div>
			<ion-card v-if="isActive" disabled="true" color="medium">
				<ion-card-content v-if="fields[field.item].value" class="flex-row-left-container">
					<ion-item lines="none" class="line-of-3" v-for="field in itemContents" :key="field.id">
						<spot-input
							:ref="field.name"
							:label="field.text"
							:required="false"
							:placeholder="null"
							:value="field.value"
							:type="field.inputType">
						</spot-input>
					</ion-item>
				</ion-card-content>
			</ion-card>
			<div class="flex-row-center-container" v-if="isActive">
				<spot-input
					:ref="fields[field.period].name"
					:label="fields[field.period].text"
					:allignLabel="fields[field.period].allignLabel"
					:required="fields[field.period].required"
					:placeholder="getPlaceHolder(field.period)"
					:enabled="!isPrinting"
					:validationState="fields[field.period].validationState"
					:value="fields[field.period].value"
					:type="fields[field.period].inputType"
					:clearInput="true"
					@textChanged="changedPeriod">
				</spot-input>
				<spot-input
					:ref="fields[field.production_line].name"
					:label="fields[field.production_line].text"
					:allignLabel="fields[field.production_line].allignLabel"
					:required="fields[field.production_line].required"
					:placeholder="getPlaceHolder(field.production_line)"
					:enabled="!isPrinting"
					:validationState="fields[field.production_line].validationState"
					:value="fields[field.production_line].value"
					:type="fields[field.production_line].inputType"
					:clearInput="true"
					@textChanged="changedProductionLine">
				</spot-input>
				<spot-input
					:ref="fields[field.lot].name"
					:label="fields[field.lot].text"
					:allignLabel="fields[field.lot].allignLabel"
					:required="fields[field.lot].required"
					:placeholder="getPlaceHolder(field.lot)"
					:enabled="false"
					:validationState="fields[field.lot].validationState"
					:value="fields[field.lot].value"
					:type="fields[field.lot].inputType"
					:clearInput="true">
				</spot-input>
			</div>
			<div class="flex-row-center-container" v-if="isActive">
				<spot-select
					:ref="fields[field.print_mode].name"
					:label="fields[field.print_mode].text"
					:allignLabel="fields[field.print_mode].allignLabel"
					:required="fields[field.print_mode].required"
					:placeholder="getPlaceHolder(field.print_mode)"
					:enabled="!isPrinting"
					:validationState="fields[field.print_mode].validationState"
					:value="fields[field.print_mode].value"
					:options="print_modes"
					:optionField="fields[field.print_mode].refField"
					selectedLabel="Selezionato"
					deselectLabel="rimuovi"
					selectLabel="seleziona"
					@selected="detectPrintModeSelection"
					@unselected="detectPrintModeUnselection">
				</spot-select>
				<spot-input
					:ref="fields[field.label_quantity].name"
					:label="fields[field.label_quantity].text"
					:allignLabel="fields[field.label_quantity].allignLabel"
					:required="fields[field.label_quantity].required"
					:placeholder="getPlaceHolder(field.label_quantity)"
					:enabled="!isPrinting"
					:validationState="fields[field.label_quantity].validationState"
					:value="fields[field.label_quantity].value"
					:type="fields[field.label_quantity].inputType"
					:clearInput="true"
					@textChanged="changedQuantity">
				</spot-input>
				<spot-button
					fill="outline"
					size="large"
					color="primary"
					icon="print"
					:enabled="checkFields()"
					:expand="null"
					:text="(isPrinting == true) ? labelEndPrint : labelPrint"
					@clicked="(isPrinting == true) ? cancelQueue(false) : executePrint()">
				</spot-button>
			</div>
		</ion-content>
 </ion-page>
</template>

<style scoped>
	.flex-column-center-container {
		margin-top: 1em;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.flex-column-left-container {
		margin-top: 1em;
		text-align: left;
		display: flex;
		flex-direction: column;
		justify-content: left;
		align-items: left;
	}
	.flex-row-center-container {
		margin-top: 1em;
		text-align: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	.flex-row-left-container {
		margin-top: 1em;
		text-align: left;
		display: flex;
		flex-direction: row;
		/*justify-content: left;*/
		/*align-items: left;*/
		flex-wrap: wrap;
	}
	.line-of-3 {
		flex: 33%;
	}
</style>

<script>
import { IonCard, IonCardContent, IonContent, IonItem, IonPage, IonProgressBar } from '@ionic/vue';
import { print } from "ionicons/icons";
import { defineComponent } from 'vue';
import SpotButton from "../components/SpotButton.vue";
import SpotDate from "../components/SpotDate.vue";
import SpotInput from "../components/SpotInput.vue";
import SpotSelect from "../components/SpotSelect.vue";
import SpotSelectCustom from "../components/SpotSelectCustom.vue";
import SpotTitle from "../components/SpotTitle.vue";
import { checkUserSession, getUserInfo, settings, showMsgError, showMsgInfo, showMsgWarning } from "../plugins/common.js";
import { createLotLabel, executePrinterAction, getAllTablesData, tablesData, tablesToBeRefreshed, updateBranch, updatePrinter } from "../plugins/customDatabase.js";

	export default defineComponent({
		name: 'LottoPrint',
		props: {
			pageTitle: { type: String, default: "Stampa etichetta Lotto" },
			unselected: { type: String, default: "Nessuno" },
			labelPrint: { type: String, default: "Stampa Etichette" },
			labelEndPrint: { type: String, default: "Termina Stampa Etichette" },
			messageTitleCancelQueue: { type: String, default: "Cancellazione coda di Stampa" },
			successCancelQueue: { type: String, default: "Coda di Stampa cancellata" },
			errorCancelQueue: { type: String, default: "Coda di Stampa non cancellata" },
			successPrint: { type: String, default: "Etichette stampate correttamente" },
			userCancelPrint: { type: String, default: "Stampa Etichette annullata dall'operatore" },
			errorPrint: { type: String, default: "Erore durante l'attività di stampa Etichette" },
		},
		components: {
			IonPage,
			IonContent,
			SpotTitle,
			IonProgressBar,
			SpotSelect,
			SpotSelectCustom,
			IonCard,
			IonCardContent,
			IonItem,
			SpotButton,
			SpotInput,
			SpotDate,
		},
		data: () => ({
			isActive: null,
			isFirstLoad: true,
			isPrinting: null,
			undoPrint: false,
			branchId: null,
			field: {
				printer: 0,
				print_template: 1,
				production_date: 2,
				item: 3,
				description: 4,
				period: 5,
				production_line: 6,
				lot: 7,
				print_mode: 8,
				label_quantity: 9,
			},
			fields: [
				{ id: 0, name: 'printer', text: 'Stampante', allignLabel: false, placeholder: 'Seleziona Stampante', inputType: "combo", validationState: null, defaultValue: null, value: null, required: true, refField: 'name' },
				{ id: 1, name: 'print_template', text: 'Modello Etichetta', allignLabel: false, placeholder: 'Seleziona Modello Etichetta', inputType: "combo", validationState: null, defaultValue: null, value: null, required: true, refField: 'name' },
				{ id: 2, name: 'production_date', text: 'Data Produzione', allignLabel: false, placeholder: 'Inserisci Data Produzione', inputType: "datetime", validationState: null, defaultValue: new Date().toISOString(), value: null, required: true, refField: null },
				{ id: 3, name: 'item', text: 'Codice Articolo', allignLabel: false, placeholder: 'Seleziona Codice Articolo', inputType: "combo", validationState: null, defaultValue: null, value: null, required: true, refField: 'code' },
				{ id: 4, name: 'description', text: 'Descrizione', allignLabel: false, placeholder: 'Seleziona Descrizione', inputType: "combo", validationState: null, defaultValue: null, value: null, required: true, refField: 'id' },
				{ id: 5, name: 'period', text: 'Periodo', allignLabel: false, placeholder: 'Inserisci Periodo', inputType: "text", validationState: null, defaultValue: null, value: null, required: true, refField: null },
				{ id: 6, name: 'production_line', text: 'Linea di Produzione', allignLabel: false, placeholder: 'Inserisci Linea', inputType: "text", validationState: null, defaultValue: null, value: null, required: true, refField: null },
				{ id: 7, name: 'lot', text: 'Lotto', allignLabel: false, placeholder: 'Inserisci Lotto', inputType: "text", validationState: null, defaultValue: null, value: null, required: true, refField: null },
				{ id: 8, name: 'print_mode', text: 'Modalità', allignLabel: false, placeholder: 'Seleziona Modalità', inputType: "combo", validationState: null, defaultValue: null, value: null, required: true, refField: 'text' },
				{ id: 9, name: 'label_quantity', text: 'Numero Etichette', allignLabel: false, placeholder: 'Inserisci Nr. Etichette', inputType: "number", validationState: null, defaultValue: null, value: null, required: true, refField: null },
			],
			itemContents: [
				{ id: 0, name: 'description', text: 'Descrizione', inputType: "text", value: null },
				{ id: 1, name: 'details', text: 'Dettagli', inputType: "text", value: null },
				{ id: 2, name: 'packages', text: 'Imballaggio', inputType: "text", value: null },
				{ id: 3, name: 'barcode', text: 'EAN 13 | ITF 14', inputType: "text", value: null },
				{ id: 4, name: 'weight', text: 'Peso', inputType: "number", value: null },
				{ id: 5, name: 'quantity', text: 'Quantità', inputType: "number", value: null },
				{ id: 6, name: 'expiration', text: 'Scadenza', inputType: "text", value: null },
				{ id: 7, name: 'month_end', text: 'Fine Mese', inputType: "text", value: null },
				{ id: 8, name: 'durability', text: 'Durabilità', inputType: "number", value: null },
			],
			print_mode: {
				manual: 0,
				automatic: 1,
			},
			print_modes: [
				{ id: 0, name: 'Off_line', text: 'Manuale', value: 2, rotation: 'N' },
				{ id: 1, name: 'On_line', text: 'Automatica', value: 1, rotation: 'I' },
			],
			print,
			showMsgInfo,
			showMsgWarning,
			showMsgError,
			checkUserSession,
			settings,
			tablesData,
			tablesToBeRefreshed,
			getAllTablesData,
			getUserInfo,
			updateBranch,
			createLotLabel,
			updatePrinter,
			executePrinterAction,
		}),
		async ionViewWillEnter() {
			this.setDefault();
			await this.getAllRequiredTables();
			this.assignCalculatedFields();
			this.isFirstLoad = false;
			this.isActive = true;
			/*
			window.addEventListener('beforeunload', (event) => {
				if (this.isActive || !this.isActive) {
					event.returnValue = 'You have unfinished changes!';
				}
			});
			*/
		},
		ionViewWillLeave() {
			this.isActive = false;
		},
		/*
		computed: {
			getProductionDate() {
				return this.fields[this.field.production_date].value;
			},
		},
		watch: {
			getProductionDate() {
				console.log("Watch");
				console.log(this.fields[this.field.production_date].value);
			}
		},
		*/
		methods: {
			//Settings
			getPlaceHolder(index) {
				return ((this.settings.showFieldsPlaceholder) ? (this.fields[index].required ? this.fields[index].placeholder : this.unselected ) : '')
			},
			customDescription ({ description, details, packages }) {
				return description + (details ? details : "") + (packages ? packages : "")
			},
			//Database
			async getAllRequiredTables() {
				this.tablesToBeRefreshed.branches = true;
				this.tablesToBeRefreshed.printers = true;
				this.tablesToBeRefreshed.printTemplates = true;
				this.tablesToBeRefreshed.items = true;
				if (this.branchId) {
					await this.getAllTablesData(this.branchId);
				}
				else {
					await this.getAllTablesData(null);
				}
			},
			//Actions
			setDefault() {
				//await this.getPrinter()
				if (this.isFirstLoad) {
					this.branchId = this.getUserInfo().branch_id;
					this.fields.forEach(element => element.value = element.defaultValue);
					this.fields[this.field.print_mode].value = this.print_modes[this.print_mode.manual];
				}
			},
			detectPrinterSelection(value) {
				this.fields[this.field.printer].value = value;
				this.fields[this.field.printer].validationState = true;
			},
			detectPrinterUnselection() {
				this.fields[this.field.printer].value = this.fields[this.field.printer].defaultValue;
				this.fields[this.field.printer].validationState = false;
			},
			detectPrintTemplateSelection(value) {
				this.fields[this.field.print_template].value = value;
				this.fields[this.field.print_template].validationState = true;
			},
			detectPrintTemplateUnselection() {
				this.fields[this.field.print_template].value = this.fields[this.field.print_template].defaultValue;
				this.fields[this.field.print_template].validationState = false;
			},
			detectItemSelection(value) {
				this.updateItemContents(value);
				this.fields[this.field.item].value = value;
				this.fields[this.field.description].value = value;
				this.fields[this.field.item].validationState = true;
				this.fields[this.field.description].validationState = true;
			},
			detectItemUnselection() {
				this.fields[this.field.item].value = this.fields[this.field.item].defaultValue;
				this.fields[this.field.description].value = this.fields[this.field.description].defaultValue;
				this.fields[this.field.item].validationState = false;
				this.fields[this.field.description].validationState = false;
			},
			changedProductionDate(value) {
				this.fields[this.field.production_date].value = value;
				(!value || value.length == 0)? this.fields[this.field.production_date].validationState = false : this.fields[this.field.production_date].validationState = true
				this.createLotto();
			},
			changedPeriod(value) {
				this.fields[this.field.period].value = value;
				(!value || value.length == 0)? this.fields[this.field.period].validationState = false : this.fields[this.field.period].validationState = true
				this.createLotto();
			},
			changedProductionLine(value) {
				this.fields[this.field.production_line].value = value;
				(!value || value.length == 0)? this.fields[this.field.production_line].validationState = false : this.fields[this.field.production_line].validationState = true
				this.createLotto();
			},
			detectPrintModeSelection(value) {
				this.fields[this.field.print_mode].value = value;
				this.fields[this.field.print_mode].validationState = true;
			},
			detectPrintModeUnselection() {
				this.fields[this.field.print_mode].value = this.fields[this.field.print_mode].defaultValue;
				this.fields[this.field.print_mode].validationState = false;
			},
			changedQuantity(value) {
				this.fields[this.field.label_quantity].value = value;
				(!value || value.length == 0)? this.fields[this.field.label_quantity].validationState = false : this.fields[this.field.label_quantity].validationState = true
			},
			updateItemContents(row) {
				Object.getOwnPropertyNames(row).forEach(rowField => {
					this.itemContents.forEach(pageElement => {
						if (rowField != "id" && rowField == pageElement.name) {
							pageElement.value = row[rowField];
						}
					});
				});
			},
			checkFields() {
				let ret = true;
				this.fields.forEach(element => {if (element.required && !element.value) ret = false});
				if (!this.fields[this.field.label_quantity].value || this.fields[this.field.label_quantity].value <= 0) ret = false;
				return ret;
			},
			createLotto() {
				if (this.tablesData.currentBranch) {
					let currDate = new Date(this.fields[this.field.production_date].value);
					this.fields[this.field.lot].value = this.tablesData.currentBranch.lot_prefix;
					this.fields[this.field.lot].value += Math.floor((currDate - new Date(currDate.getFullYear().toString(), 0, 0)) / (1000 * 60 * 60 * 24)).toString().padStart(3, '0');
					this.fields[this.field.lot].value += currDate.getFullYear().toString().substring(2,4);
					this.fields[this.field.lot].value += this.tablesData.currentBranch.lot_branch;
					this.fields[this.field.lot].value += this.fields[this.field.period].value;
					this.fields[this.field.lot].value += this.fields[this.field.production_line].value;
				}
				else {
					this.fields[this.field.lot].value = null;
				}
			},
			async cancelQueue(showMessage) {
				this.undoPrint = true;
				await this.executePrinterAction(`printers/` + this.fields[this.field.printer].value.id + `?do=cancel_print_queue`)
				.then(() => {
					if (showMessage) {
						this.showMsgInfo(this.messageTitleCancelQueue,this.successCancelQueue);
					}
				})
				.catch(error => {
					this.checkUserSession(error, "warning");
					this.showMsgError(this.messageTitleCancelQueue, this.errorCancelQueue);
				})
			},
			async printLabel(isLastLabel) {
				let currDate = new Date(this.fields[this.field.production_date].value);
				let printRequest = `printers/` + this.fields[this.field.printer].value.id + `?do=print_with_status` +
					`&darkness=` + this.fields[this.field.printer].value.temperature +
					`&label_id=` + this.tablesData.currentLotLabel.id +
					`&code=` + this.fields[this.field.item].value.code +
					`&description=` + this.fields[this.field.item].value.description +
					`&details=` + (this.fields[this.field.item].value.details? this.fields[this.field.item].value.details : "") +
					`&packages=` + (this.fields[this.field.item].value.packages? this.fields[this.field.item].value.packages : "") +
					`&date=` + currDate.toLocaleDateString() +
					`&day=` + Math.floor((currDate - new Date(currDate.getFullYear().toString(), 0, 0)) / (1000 * 60 * 60 * 24)).toString().padStart(3, '0') +
					`&year=` + currDate.getFullYear().toString().substring(2,4) +
					`&branch=` + this.tablesData.currentBranch.lot_branch +
					`&period=` + this.fields[this.field.period].value +
					`&line=` + this.fields[this.field.production_line].value;
				await this.executePrinterAction(printRequest)
				.then(() => {
					if (isLastLabel == true) this.showMsgInfo(this.labelPrint,this.successPrint);
				})
				.catch(error => {
					this.checkUserSession(error, "warning");
					this.showMsgError(this.labelPrint, this.errorPrint);
				})
			},
			async executePrint() {
				this.isPrinting = true;
				for (let i = 1; i <= this.fields[this.field.label_quantity].value; i += 1) {
					if (this.undoPrint == true) {
						this.showMsgWarning(this.labelPrint, this.userCancelPrint);
						this.undoPrint == false
						break;
					}
					await this.sendPrintTemplateToPrinter();
					await this.createNewLabel();
					if (i == this.fields[this.field.label_quantity].value) {
						await this.printLabel(true);
					}
					else {
						await this.printLabel(false);
					}
					// await this.updateBranchValues();
				}
				this.isPrinting = false;
				this.refreshPage();
			},
			refreshPage() {
				//this.createLotto();
				this.fields[this.field.label_quantity].value = null;
			},
			assignCalculatedFields() {
				if (!this.fields[this.field.period].value) {
					(!this.tablesData.currentBranch || !this.tablesData.currentBranch.turn) ? this.fields[this.field.period].value = null : this.fields[this.field.period].value = this.tablesData.currentBranch.turn;
				}
				if (!this.fields[this.field.production_line].value) {
					(!this.tablesData.currentBranch || !this.tablesData.currentBranch.production_line) ? this.fields[this.field.production_line].value = null : this.fields[this.field.production_line].value = this.tablesData.currentBranch.production_line;
				}
				this.fields.forEach(element => (element.required == true? (element.value == null ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.createLotto();
			},
			async sendPrintTemplateToPrinter() {
				let myPrinter = this.fields[this.field.printer].value.id;
				let myRecord = {
					printers: {
						print_template_id: this.fields[this.field.print_template].value.id,
					}
				};
				await this.updatePrinter(myPrinter, myRecord);
			},
			async createNewLabel() {
				let myRecord = {
					labels: {
						is_online: this.fields[this.field.print_mode].value.id,
						item_id: this.fields[this.field.item].value.id,
						print_template_id: this.fields[this.field.print_template].value.id,
						lot: this.fields[this.field.lot].value,
						production_date: this.fields[this.field.production_date].value,
					}
				};
				await this.createLotLabel(myRecord);
			},
			async updateBranchValues() {
				if (this.tablesData.currentBranch) {
					let myRecord = {
						branches: {
							turn: this.fields[this.field.period].value,
							production_line: this.fields[this.field.production_line].value,
						}
					};
					await this.updateBranch(this.branchId,myRecord);
				}
			},
		},
	});
</script>
